const palette = {
  primaryGreen: '#BCCEBE',
  secondaryGreen: '#50A662',
  lightGray: '#F5F5F5',
  gold: '#DBCDAB',
  dark: '#000000',
  white: '#FFFFFF',
};

module.exports = palette;
