import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import palette from '../colors';
import HeaderImage from '../assets/heade.png';
import test from '../assets/test.svg';
import logo from '../assets/logo.svg';
import illus1 from '../assets/illus1.jpg';
import illus2 from '../assets/illus2.jpg';

import { Spring, easings } from '@react-spring/web';
import { animated } from '@react-spring/web';
import flIllus from '../assets/fl.svg';
import fcIllus from '../assets/fc.svg';
import rlIllus from '../assets/rl.svg';
import rcIllus from '../assets/rc.svg';
import melIllus from '../assets/mel.svg';
import PhotoAlbum from 'react-photo-album';
import VisibilitySensor from 'react-visibility-sensor';
import LogoWhite from '../assets/logo_white.svg';
import ReedWhite from '../assets/reed.svg';

const photos1 = [
  {
    src: require('../assets/photos/1.jpeg'),
    width: 1200,
    height: 1600,
  },
  {
    src: require('../assets/photos/2.jpeg'),
    width: 1289,
    height: 1576,
  },
  {
    src: require('../assets/photos/3.jpeg'),
    width: 1290,
    height: 1461,
  },
  {
    src: require('../assets/photos/4.jpeg'),
    width: 1290,
    height: 1177,
  },
  {
    src: require('../assets/photos/5.jpeg'),
    width: 579,
    height: 730,
  },
  {
    src: require('../assets/photos/6.jpeg'),
    width: 728,
    height: 1600,
  },
  {
    src: require('../assets/photos/7.jpeg'),
    width: 768,
    height: 1024,
  },
];

const photos2 = [
  {
    src: require('../assets/photos/8.jpeg'),
    width: 840,
    height: 1024,
  },
  {
    src: require('../assets/photos/9.jpeg'),
    width: 836,
    height: 1024,
  },
  {
    src: require('../assets/photos/10.jpeg'),
    width: 1030,
    height: 1600,
  },
  {
    src: require('../assets/photos/11.jpeg'),
    width: 1289,
    height: 1478,
  },
  {
    src: require('../assets/photos/12.jpeg'),
    width: 1288,
    height: 1600,
  },
];

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { strawAnim: false, isMd: false };
    this.strawAnim = React.createRef();
  }

  handleScroll = () => {
    const elementPosition = this.strawAnim.current
      ? this.strawAnim.current.getBoundingClientRect().top
      : 0;
    const windowHeight = window.innerHeight;
    if (elementPosition < windowHeight && !this.state.strawAnim) {
      this.setState({ strawAnim: true });
    } else if (elementPosition > windowHeight && this.state.strawAnim) {
      this.setState({ strawAnim: false });
    }
  };

  componentDidMount() {
    const checkScreenSize = () => {
      this.setState({ isMd: window.innerWidth > 768 });
    };
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <div
        className='mt-3'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          // Added max width for desktop
        }}
      >
        <Container className='mb-3'>
          <div
            style={{
              width: '100%',
              height: 100,
              overflow: 'hidden',
              backgroundRepeat: 'repeat',
              backgroundPosition: 'center',
              position: 'relative',
            }}
          >
            <img style={{ height: '100%' }} src={test}></img>
            <div
              style={{
                height: 80,
                width: 80,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: 40,
                backgroundColor: palette.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img style={{ width: '80%', height: '80%' }} src={logo}></img>
            </div>
          </div>
        </Container>
        <Row
          className='mb-3 py-3'
          style={{
            margin: 0,
            padding: 0,
            backgroundColor: palette.primaryGreen,
            width: '100%',
          }}
        >
          <Col>
            <Container>
              <Row>
                <Col xs={12} md={6}>
                  <h1
                    style={{
                      textAlign: 'left',
                      fontFamily: 'Montserrat',
                      fontWeight: 800,
                      color: palette.white,
                    }}
                  >
                    Votre enseigne,{' '}
                    <span
                      className='px-2'
                      style={{
                        backgroundColor: palette.secondaryGreen,
                        borderRadius: 10,
                      }}
                    >
                      naturellement
                    </span>{' '}
                    mise en avant.
                  </h1>
                  <p
                    style={{
                      textAlign: 'left',
                      color: palette.white,
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                    }}
                  >
                    Chez Rozzos, nous fabriquons et commercialisons des{' '}
                    <span style={{ color: palette.secondaryGreen }}>
                      pailles en roseau
                    </span>{' '}
                    éco-responsables et personnalisées.
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        left: '50%',
                        transform: 'translate(-50%, 0%)',
                        height: '90%',
                        position: 'absolute',
                      }}
                      src={LogoWhite}
                    ></img>

                    <Spring
                      from={{ transform: 'scale(0.85)', opacity: 0.5 }}
                      to={[
                        { transform: 'scale(1)', opacity: 1 },
                        { transform: 'scale(0.85)', opacity: 0.5 },
                      ]}
                      loop
                      config={{
                        duration: 1500,
                      }}
                    >
                      {(style) => (
                        <animated.img
                          style={{
                            ...style,
                            position: 'absolute',
                            height: '90%',
                          }}
                          src={ReedWhite}
                          alt='Motif'
                        />
                      )}
                    </Spring>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>

        <Row>
          <PhotoAlbum
            style={{ width: '100%', height: '100%' }}
            layout='rows'
            photos={photos1}
          ></PhotoAlbum>
        </Row>
        <div className='p-3' style={{ maxWidth: 1200 }}>
          <Container style={{ padding: 10 }}>
            <h2
              className='mb-3'
              style={{
                textAlign: 'left',
                fontFamily: 'Montserrat',
                fontWeight: 800,
                color: palette.secondaryGreen,
              }}
            >
              Choisir nos pailles, c'est adopter :
            </h2>
            <Row className='mb-5'>
              <Col
                xs={12}
                md={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <VisibilitySensor
                  onChange={(isVisible) => {
                    console.log(isVisible);
                    this.setState({ reed1: isVisible });
                  }}
                >
                  <Row
                    className='mb-1 py-1 pt-2'
                    style={{
                      position: 'relative',
                      backgroundColor: palette.primaryGreen,
                      borderRadius: 10,
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: -12,
                        overflow: 'hidden',
                        left: -8,
                      }}
                    >
                      <Spring
                        from={{ opacity: 0, transform: 'scale(0.5)' }}
                        to={{
                          opacity: this.state.reed1 ? 1 : 0,
                          transform: this.state.reed1
                            ? 'scale(1)'
                            : 'scale(0.5)',
                        }}
                      >
                        {(style) => (
                          <animated.img
                            style={{ ...style, height: 18 }}
                            src={require('../assets/motif.png')}
                            alt='Motif'
                          />
                        )}
                      </Spring>
                    </div>
                    <h2
                      style={{
                        textAlign: 'left',
                        fontFamily: 'Montserrat',
                        fontWeight: 800,
                        color: palette.white,
                        marginBottom: 0,
                      }}
                    >
                      Le roseau
                    </h2>
                    <p
                      style={{
                        textAlign: 'left',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        color: palette.dark,
                        marginBottom: 0,
                      }}
                    >
                      quand la nature inspire une nouvelle génération de
                      pailles.
                    </p>
                  </Row>
                </VisibilitySensor>

                <Row
                  className='py-1'
                  style={{
                    backgroundColor: palette.lightGray,
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      textAlign: 'left',
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      color: palette.black,
                      marginBottom: 0,
                    }}
                  >
                    🌱 Entièrement bio-sourcé et biodégradable. <br />✋
                    Purement naturel et sans produits chimiques.
                  </p>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img style={{ width: '80%' }} src={illus1}></img>
                </div>
              </Col>
            </Row>

            {/* Section "Choisir nos pailles, c'est adopter" */}
            <VisibilitySensor
              onChange={(isVisible) => {
                this.setState({ reed2: isVisible });
              }}
            >
              <Row className='mb-5'>
                {this.state.isMd ? (
                  <>
                    <Col xs={12} md={6}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <img style={{ width: '40%' }} src={illus2}></img>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Row
                        className='mb-1 py-1 pt-2'
                        style={{
                          backgroundColor: palette.primaryGreen,
                          borderRadius: 10,
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: -12,
                            overflow: 'hidden',
                            left: -8,
                          }}
                        >
                          <Spring
                            from={{ opacity: 0, transform: 'scale(0.5)' }}
                            to={{
                              opacity: this.state.reed2 ? 1 : 0,
                              transform: this.state.reed2
                                ? 'scale(1)'
                                : 'scale(0.5)',
                            }}
                          >
                            {(style) => (
                              <animated.img
                                style={{ ...style, height: 18 }}
                                src={require('../assets/motif.png')}
                                alt='Motif'
                              />
                            )}
                          </Spring>
                        </div>
                        <h2
                          style={{
                            textAlign: 'left',
                            fontFamily: 'Montserrat',
                            fontWeight: 800,
                            color: palette.white,
                            marginBottom: 0,
                          }}
                        >
                          La personnalisation
                        </h2>
                        <p
                          style={{
                            textAlign: 'left',
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            color: palette.dark,
                            marginBottom: 0,
                          }}
                        >
                          là où chaque détail compte pour amplifier votre image
                          de marque.
                        </p>
                      </Row>
                      <Row
                        className='py-1'
                        style={{
                          backgroundColor: palette.lightGray,
                          borderRadius: 10,
                        }}
                      >
                        <p
                          style={{
                            textAlign: 'left',
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            color: palette.black,
                            marginBottom: 0,
                          }}
                        >
                          🔖 Augmentez la notoriété avec votre logo gravé.
                          <br /> 📲 Boostez votre visibilité sur les réseaux
                          sociaux.
                          <br /> 🔄 Incitez à la réutilisation et fidélisez vos
                          clients.
                        </p>
                      </Row>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col
                      xs={12}
                      md={6}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Row
                        className='mb-1 py-1 pt-2'
                        style={{
                          backgroundColor: palette.primaryGreen,
                          borderRadius: 10,
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: -12,
                            overflow: 'hidden',
                            left: -8,
                          }}
                        >
                          <Spring
                            from={{ opacity: 0, transform: 'scale(0.5)' }}
                            to={{
                              opacity: this.state.reed2 ? 1 : 0,
                              transform: this.state.reed2
                                ? 'scale(1)'
                                : 'scale(0.5)',
                            }}
                          >
                            {(style) => (
                              <animated.img
                                style={{ ...style, height: 18 }}
                                src={require('../assets/motif.png')}
                                alt='Motif'
                              />
                            )}
                          </Spring>
                        </div>
                        <h2
                          style={{
                            textAlign: 'left',
                            fontFamily: 'Montserrat',
                            fontWeight: 800,
                            color: palette.white,
                            marginBottom: 0,
                          }}
                        >
                          La personnalisation
                        </h2>
                        <p
                          style={{
                            textAlign: 'left',
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            color: palette.dark,
                            marginBottom: 0,
                          }}
                        >
                          là où chaque détail compte pour amplifier votre image
                          de marque.
                        </p>
                      </Row>
                      <Row
                        className='py-1'
                        style={{
                          backgroundColor: palette.lightGray,
                          borderRadius: 10,
                        }}
                      >
                        <p
                          style={{
                            textAlign: 'left',
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            color: palette.black,
                            marginBottom: 0,
                          }}
                        >
                          🔖 Augmentez la notoriété avec votre logo gravé.
                          <br /> 📲 Boostez votre visibilité sur les réseaux
                          sociaux.
                          <br /> 🔄 Incitez à la réutilisation et fidélisez vos
                          clients.
                        </p>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <img style={{ width: '40%' }} src={illus2}></img>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </VisibilitySensor>

            {/* Section "D'une limonade pétillante à un cocktail onctueux" */}

            <Row className='mb-5'>
              <Col
                className='py-1 pt-3'
                xs={12}
                md={12}
                style={{
                  borderRadius: 10,
                  backgroundColor: palette.primaryGreen,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    position: 'absolute',
                    top: -12,
                    overflow: 'hidden',
                    left: 0,
                  }}
                >
                  <Spring
                    from={{ opacity: 0, transform: 'scale(0.5)' }}
                    to={{
                      opacity: this.state.reed3 ? 1 : 0,
                      transform: this.state.reed3 ? 'scale(1)' : 'scale(0.5)',
                    }}
                  >
                    {(style) => (
                      <animated.img
                        style={{ ...style, height: 18 }}
                        src={require('../assets/motif.png')}
                        alt='Motif'
                      />
                    )}
                  </Spring>
                </div>
                <VisibilitySensor
                  onChange={(isVisible) => {
                    console.log(isVisible);
                    this.setState({ reed3: isVisible });
                  }}
                >
                  <h2
                    style={{
                      textAlign: 'left',
                      fontFamily: 'Montserrat',
                      fontWeight: 800,
                      color: palette.white,
                    }}
                  >
                    D'une{' '}
                    <span style={{ color: palette.secondaryGreen }}>
                      limonade pétillante
                    </span>{' '}
                    à un{' '}
                    <span style={{ color: palette.secondaryGreen }}>
                      cocktail
                    </span>
                    , nos pailles sont prêtes pour chaque aventure gustative.
                  </h2>
                </VisibilitySensor>
                <p style={{ fontFamily: 'Montserrat' }}>Notre gamme :</p>
                <Col>
                  <Row>
                    <Col className='mb-2' xs={12} md={4}>
                      <div
                        className='py-2'
                        style={{
                          height: 200,
                          backgroundColor: 'gray',
                          backgroundColor: palette.white,
                          borderRadius: 10,
                        }}
                      >
                        <Row style={{ height: '100%' }}>
                          <Col
                            xs={4}
                            md={4}
                            style={{
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img style={{ height: '100%' }} src={flIllus}></img>
                          </Col>
                          <Col
                            style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                            md={8}
                            xs={8}
                          >
                            <Row>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 800,
                                  color: palette.secondaryGreen,
                                  marginBottom: 0,
                                }}
                              >
                                Longues et fines
                              </p>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                }}
                              >
                                L'élégance pour vos cocktails.
                              </p>
                            </Row>
                            <Row>
                              <p
                                style={{
                                  marginBottom: 0,
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                  color: palette.gold,
                                }}
                              >
                                Longueur : 200mm
                                <br /> Epaisseur : 5-8mm
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className='mb-2' xs={12} md={4}>
                      <div
                        className='py-2'
                        style={{
                          height: 200,
                          backgroundColor: 'gray',
                          backgroundColor: palette.white,
                          borderRadius: 10,
                        }}
                      >
                        <Row style={{ height: '100%' }}>
                          <Col
                            xs={4}
                            md={4}
                            style={{
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img style={{ height: '100%' }} src={fcIllus}></img>
                          </Col>
                          <Col
                            style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                            md={8}
                            xs={8}
                          >
                            <Row>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 800,
                                  color: palette.secondaryGreen,
                                  marginBottom: 0,
                                }}
                              >
                                Courtes et fines
                              </p>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                }}
                              >
                                Idéale pour les verres plus courts.
                              </p>
                            </Row>
                            <Row>
                              <p
                                style={{
                                  marginBottom: 0,
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                  color: palette.gold,
                                }}
                              >
                                Longueur : 140mm
                                <br /> Epaisseur : 5-8mm
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className='mb-2' xs={12} md={4}>
                      <div
                        className='py-2'
                        style={{
                          height: 200,
                          backgroundColor: 'gray',
                          backgroundColor: palette.white,
                          borderRadius: 10,
                        }}
                      >
                        <Row style={{ height: '100%' }}>
                          <Col
                            xs={4}
                            md={4}
                            style={{
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img style={{ height: '100%' }} src={rlIllus}></img>
                          </Col>
                          <Col
                            style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                            md={8}
                            xs={8}
                          >
                            <Row>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 800,
                                  color: palette.secondaryGreen,
                                  marginBottom: 0,
                                }}
                              >
                                Longues et épaisses
                              </p>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                }}
                              >
                                Pour les milkshakes et smoothies gourmands.
                              </p>
                            </Row>
                            <Row>
                              <p
                                style={{
                                  marginBottom: 0,
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                  color: palette.gold,
                                }}
                              >
                                Longueur : 200mm
                                <br /> Epaisseur : 9-11mm
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='mb-2' xs={12} md={4}>
                      <div
                        className='py-2'
                        style={{
                          height: 200,
                          backgroundColor: 'gray',
                          backgroundColor: palette.white,
                          borderRadius: 10,
                        }}
                      >
                        <Row style={{ height: '100%' }}>
                          <Col
                            xs={4}
                            md={4}
                            style={{
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img style={{ height: '100%' }} src={rcIllus}></img>
                          </Col>
                          <Col
                            style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                            md={8}
                            xs={8}
                          >
                            <Row>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 800,
                                  color: palette.secondaryGreen,
                                  marginBottom: 0,
                                }}
                              >
                                Courtes et épaisses
                              </p>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                }}
                              >
                                La force dans un format compact.
                              </p>
                            </Row>
                            <Row>
                              <p
                                style={{
                                  marginBottom: 0,
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                  color: palette.gold,
                                }}
                              >
                                Longueur : 140mm
                                <br /> Epaisseur : 9-11mm
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className='mb-2' xs={12} md={4}>
                      <div
                        className='py-2'
                        style={{
                          height: 200,
                          backgroundColor: 'gray',
                          backgroundColor: palette.white,
                          borderRadius: 10,
                        }}
                      >
                        <Row style={{ height: '100%' }}>
                          <Col
                            xs={4}
                            md={4}
                            style={{
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              style={{ height: '100%' }}
                              src={melIllus}
                            ></img>
                          </Col>{' '}
                          <Col
                            style={{
                              height: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                            md={8}
                            xs={8}
                          >
                            <Row>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 800,
                                  color: palette.secondaryGreen,
                                  marginBottom: 0,
                                }}
                              >
                                Longues et super épaisses
                              </p>
                              <p
                                style={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                }}
                              >
                                Conçue pour les mélanges ultra-onctueux.
                              </p>
                            </Row>
                            <Row>
                              <p
                                style={{
                                  marginBottom: 0,
                                  fontFamily: 'Montserrat',
                                  fontWeight: 500,
                                  color: palette.gold,
                                }}
                              >
                                Longueur : 200mm
                                <br /> Epaisseur : 12+mm
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
            <Row className='mb-5'>
              <PhotoAlbum
                style={{ width: '100%', height: 500 }}
                layout='rows'
                photos={photos2}
              ></PhotoAlbum>
            </Row>

            {/* Processus de commande */}
            <Row className='mb-5'>
              <Col ref={this.strawAnim} xs={3} md={12}>
                <Spring
                  width={
                    this.state.isMd
                      ? this.state.strawAnim
                        ? '100%'
                        : '0%'
                      : '100%'
                  }
                  height={
                    !this.state.isMd
                      ? this.state.strawAnim
                        ? '100%'
                        : '0%'
                      : '100%'
                  }
                >
                  {(styles) => (
                    <animated.div
                      style={{
                        backgroundColor: 'gray',
                        position: 'relative',
                        borderRadius: 18,
                        backgroundColor: palette.gold,
                        ...styles,
                        height: this.state.isMd ? 36 : styles.height,
                        width: !this.state.isMd ? 36 : styles.width,
                      }}
                    >
                      <div
                        style={{
                          height: 32,
                          width: 32,
                          borderRadius: 18,
                          backgroundColor: palette.white,
                          position: 'absolute',
                          top: 2,
                          left: 2,
                        }}
                      ></div>
                      <div
                        style={{
                          height: 38,
                          width: 38,
                          borderRadius: 20,
                          borderRight: this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          borderBottom: !this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          position: 'absolute',
                          top: this.state.isMd ? -1 : '5%',
                          left: this.state.isMd ? '5%' : -1,
                        }}
                      ></div>
                      <div
                        style={{
                          height: 38,
                          width: 38,
                          borderRadius: 20,
                          borderRight: this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          borderBottom: !this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          position: 'absolute',
                          top: this.state.isMd ? -1 : '25%',
                          left: this.state.isMd ? '25%' : -1,
                        }}
                      ></div>
                      <div
                        style={{
                          height: 38,
                          width: 38,
                          borderRadius: 20,
                          borderRight: this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          borderBottom: !this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          position: 'absolute',
                          top: this.state.isMd ? -1 : '45%',
                          left: this.state.isMd ? '45%' : -1,
                        }}
                      ></div>
                      <div
                        style={{
                          height: 38,
                          width: 38,
                          borderRadius: 20,
                          borderRight: this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          borderBottom: !this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          position: 'absolute',
                          top: this.state.isMd ? -1 : '65%',
                          left: this.state.isMd ? '65%' : -1,
                        }}
                      ></div>
                      <div
                        style={{
                          height: 38,
                          width: 38,
                          borderRadius: 20,
                          borderRight: this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          borderBottom: !this.state.isMd
                            ? '4px solid ' + palette.secondaryGreen
                            : undefined,
                          position: 'absolute',
                          top: this.state.isMd ? -1 : '85%',
                          left: this.state.isMd ? '85%' : -1,
                        }}
                      ></div>
                    </animated.div>
                  )}
                </Spring>
              </Col>
              <Col xs={9} md={12}>
                <Row
                  md={12}
                  xs={9}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Spring
                    from={{ opacity: 0 }}
                    delay={100}
                    to={{ opacity: this.state.strawAnim ? 1 : 0 }}
                  >
                    {(styles) => (
                      <Col md={2} xs={12} className='p-2'>
                        <animated.div
                          className='p-1'
                          style={{
                            ...styles,
                            borderRadius: 10,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: palette.lightGray,
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              margin: 0,
                              fontFamily: 'Montserrat',
                              fontWeight: 600,
                              color: palette.secondaryGreen,
                            }}
                          >
                            Nos graphistes traitent votre logo
                          </p>
                        </animated.div>
                      </Col>
                    )}
                  </Spring>
                  <Spring
                    delay={200}
                    from={{ opacity: 0 }}
                    to={{ opacity: this.state.strawAnim ? 1 : 0 }}
                  >
                    {(styles) => (
                      <Col md={2} xs={12} className='p-2'>
                        <animated.div
                          className='p-1'
                          style={{
                            ...styles,
                            borderRadius: 10,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: palette.lightGray,
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              margin: 0,
                              fontFamily: 'Montserrat',
                              fontWeight: 600,
                            }}
                          >
                            Vous recevez un échantillon
                          </p>
                        </animated.div>
                      </Col>
                    )}
                  </Spring>
                  <Spring
                    delay={300}
                    from={{ opacity: 0 }}
                    to={{ opacity: this.state.strawAnim ? 1 : 0 }}
                  >
                    {(styles) => (
                      <Col md={2} xs={12} className='p-2'>
                        <animated.div
                          className='p-1'
                          style={{
                            ...styles,
                            borderRadius: 10,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: palette.lightGray,
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              margin: 0,
                              fontFamily: 'Montserrat',
                              fontWeight: 600,
                            }}
                          >
                            Vous passez commande
                          </p>
                        </animated.div>
                      </Col>
                    )}
                  </Spring>
                  <Spring
                    delay={400}
                    from={{ opacity: 0 }}
                    to={{ opacity: this.state.strawAnim ? 1 : 0 }}
                  >
                    {(styles) => (
                      <Col md={2} xs={12} className='p-2'>
                        <animated.div
                          className='p-1'
                          style={{
                            ...styles,
                            borderRadius: 10,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: palette.lightGray,
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              margin: 0,
                              fontFamily: 'Montserrat',
                              fontWeight: 600,
                            }}
                          >
                            Nous fabriquons votre lot
                          </p>
                        </animated.div>
                      </Col>
                    )}
                  </Spring>
                  <Spring
                    delay={500}
                    from={{ opacity: 0 }}
                    to={{ opacity: this.state.strawAnim ? 1 : 0 }}
                  >
                    {(styles) => (
                      <Col md={2} xs={12} className='p-2'>
                        <animated.div
                          className='p-1'
                          style={{
                            ...styles,
                            borderRadius: 10,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: palette.lightGray,
                          }}
                        >
                          <p
                            style={{
                              textAlign: 'center',
                              margin: 0,
                              fontFamily: 'Montserrat',
                              fontWeight: 600,
                              color: palette.secondaryGreen,
                            }}
                          >
                            Vous êtes livré !
                          </p>
                        </animated.div>
                      </Col>
                    )}
                  </Spring>
                </Row>
              </Col>
            </Row>
            <VisibilitySensor
              onChange={(isVisible) => {
                console.log(isVisible);
                this.setState({ reed4: isVisible });
              }}
            >
              <Row className='mb-5'>
                <Col xs={12} md={6}>
                  <Row
                    className='mb-1 py-1 pt-2'
                    style={{
                      position: 'relative',
                      backgroundColor: palette.primaryGreen,
                      borderRadius: 10,
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: -12,
                        overflow: 'hidden',
                        left: -8,
                      }}
                    >
                      <Spring
                        from={{ opacity: 0, transform: 'scale(0.5)' }}
                        to={{
                          opacity: this.state.reed4 ? 1 : 0,
                          transform: this.state.reed4
                            ? 'scale(1)'
                            : 'scale(0.5)',
                        }}
                      >
                        {(style) => (
                          <animated.img
                            style={{ ...style, height: 18 }}
                            src={require('../assets/motif.png')}
                            alt='Motif'
                          />
                        )}
                      </Spring>
                    </div>
                    <h2
                      style={{
                        textAlign: 'left',
                        fontFamily: 'Montserrat',
                        fontWeight: 800,
                        color: palette.white,
                        marginBottom: 0,
                      }}
                    >
                      Nos clients
                    </h2>
                    <p
                      style={{
                        textAlign: 'left',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        color: palette.dark,
                        marginBottom: 0,
                      }}
                    >
                      à l’internationnal, la crème de la crème opte pour le
                      vert, et c'est avec nous !
                    </p>
                  </Row>
                </Col>

                <Col xs={12} md={6}>
                  <div style={{ backgroundColor: 'gray' }}>
                    {/* Images à droite du texte */}
                  </div>
                </Col>
              </Row>
            </VisibilitySensor>
            <Row
              className='mb-2'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/1.png')}
                ></img>
              </Col>
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/2.jpg')}
                ></img>
              </Col>
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/12.jpg')}
                ></img>
              </Col>
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/4.jpg')}
                ></img>
              </Col>
            </Row>
            <Row
              className='mb-2'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/5.jpg')}
                ></img>
              </Col>
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/6.jpg')}
                ></img>
              </Col>
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/7.jpg')}
                ></img>
              </Col>
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/8.jpg')}
                ></img>
              </Col>
            </Row>
            <Row
              className='mb-2'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/9.jpg')}
                ></img>
              </Col>
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/10.jpg')}
                ></img>
              </Col>
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/11.jpg')}
                ></img>
              </Col>
              <Col xs={3} md={3}>
                <img
                  style={{ width: '60%' }}
                  src={require('../assets/clients/13.png')}
                ></img>
              </Col>
            </Row>
          </Container>
        </div>

        <Row
          className='mb-3 py-3'
          style={{
            margin: 0,
            padding: 0,
            backgroundColor: palette.primaryGreen,
            width: '100%',
          }}
        >
          <footer>
            <Col>
              <Container>
                <Row>
                  <Col xs={12} md={4}>
                    <h3
                      style={{
                        color: palette.white,
                        fontFamily: 'Montserrat',
                        fontWeight: 800,
                      }}
                    >
                      A propos de nous
                    </h3>
                    <p
                      style={{
                        color: palette.white,
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                      }}
                    >
                      Nous fabriquons et commercialisons des{' '}
                      <span style={{ color: palette.secondaryGreen }}>
                        pailles en roseau
                      </span>
                      éco-responsables et personnalisées.
                    </p>
                  </Col>
                  <Col xs={12} md={4}>
                    <h3
                      style={{
                        color: palette.white,
                        fontFamily: 'Montserrat',
                        fontWeight: 800,
                      }}
                    >
                      Contactez-nous
                    </h3>
                    <p
                      style={{
                        color: palette.white,
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        marginBottom: 0,
                      }}
                    >
                      contact@rozzos.com
                    </p>
                    <p
                      style={{
                        color: palette.white,
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                      }}
                    >
                      59 Rue de Ponthieu <br />
                      75008, Paris
                    </p>
                  </Col>
                  <Col xs={12} md={4}>
                    <h3
                      style={{
                        color: palette.white,
                        fontFamily: 'Montserrat',
                        fontWeight: 800,
                      }}
                    >
                      Information légales
                    </h3>
                    <p
                      style={{
                        color: palette.white,
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                      }}
                    >
                      SAS Rozzos au capital social de 8 915€ <br />
                      SIREN : 978807659
                      <br />
                      RCS : Paris - TVA FR92978807659
                    </p>
                    <p
                      style={{
                        color: palette.white,
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                      }}
                    >
                      Hébergeur: OVH Cloud
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </footer>
        </Row>
        <div
          className='p-2'
          style={{
            position: 'fixed',
            borderRadius: 10,
            right: 10,
            bottom: 10,
            backgroundColor: palette.primaryGreen,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', // Added soft shadow
          }}
        >
          <p
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 600,
              margin: 0,
              color: palette.white,
            }}
          >
            Contactez-nous!
          </p>
          <a href='https://wa.me/+33644688618'>
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg'
              alt='WhatsApp'
              style={{ width: '40px', height: '40px' }}
            />
          </a>
        </div>
      </div>
    );
  }
}

export default Home;
